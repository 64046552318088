import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/default';

import Wysiwyg from 'components/content-blocks/partials/wysiwyg';
import Yoast from 'components/globals/yoast';
import SignupCTA from 'components/modal/signups';
import Wistia from 'components/video/wistia-player';

import './styles/404.scss';

export default function NotFoundPage() {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "404-2" } }) {
        edges {
          node {
            wordpress_id
        yoast_head_json {
          title
          description
          canonical
          og_title
          og_description
          og_image {
            url {
              source_url
            }
          }
          og_url
          og_type
          twitter_title
          twitter_description
          twitter_image {
            source_url
          }
        }
            acf {
              not_found {
                hero {
                  title
                  copy
                  hero_image {
                    source_url
                  }
                }
                scheduling_banner {
                  heading
                  content
                  tagline
                  button_text
                  cta_text
                  button_url
                  legal
                  alert_text
                }
                video_id
              }
            }
          }
        }
      }
    }
  `);

  const pageContext = data.allWordpressPage.edges[0].node.acf.not_found;

  return (
    <Layout>
      <Yoast { ...data.allWordpressPage.edges[0].node.yoast_head_json } />
      <section className="sick-banner-section section" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <div className="container">
          <div className="columns is-centered is-vcentered has-padding-top-xl-tablet">
            <div className="column is-7">
              <h1 className="title is-size-2-mobile">{pageContext.hero.title}</h1>
              <Wysiwyg content={ pageContext.hero.copy } />
            </div>
            <div className="column is-3 is-hidden-mobile">
              <img
                className="sick-computer-icon"
                src={ pageContext.hero.hero_image.source_url }
                role="presentation"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="sick-video-section section" data-section="sickVideo">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-9 has-text-centered">
              <h2 className="title thin">
                {pageContext.scheduling_banner.heading}
              </h2>
              <p>{pageContext.scheduling_banner.content}</p>
              <div className="has-padding-top-lg is-hidden-mobile">
                <p className="has-text-black">
                  {pageContext.scheduling_banner.tagline}
                </p>
                <br />
                <SignupCTA
                  buttonClasses="button is-medium button-green is-size-5-mobile"
                  signupModalSubmit={ pageContext.scheduling_banner.button_text }
                  signupModalLaunch={ pageContext.scheduling_banner.cta_text }
                  legalText={ pageContext.scheduling_banner.legal }
                  showInitFields
                  showModalButton
                />
              </div>
              <div className="has-padding-top-xl is-hidden-mobile">
                <Wistia hashedId={ pageContext.video_id } />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
